import store from "./store";
import {renewLogin} from "./services/Auth/actions";

let activeObserverId = null;

const messageObserver = {
    scsIFrameBindPadding: {},
    scsIFrameFreePadding: {},
};

let styleSnapShot = {};

window.addEventListener("message", function (e) {
    const body = document.body;
    const app = document.getElementById("app");
    const scsContent = document.getElementById("scsContent");
    if (e.data.type === "scsIFrameBindPadding") {
        if (undefined === styleSnapShot["app"]) styleSnapShot["app"] = {
            position: app.style.position,
            top: app.style.top,
            overflow: app.style.overflow,
        };
        if (undefined === styleSnapShot["scsContent"]) styleSnapShot["scsContent"] = {
            position: scsContent.style.position,
            top: scsContent.style.top,
        };
        if (undefined === styleSnapShot["body"]) styleSnapShot["body"] = {
            paddingLeft: body.style.paddingLeft,
            paddingRight: body.style.paddingRight,
            maxWidth: body.style.maxWidth,
            margin: body.style.margin,
        };
        app.style.position = "relative";
        app.style.top = e.data.top + "px";
        app.style.overflow = "hidden";
        scsContent.style.position = "relative";
        scsContent.style.top = e.data.scrollTop + "px";
        body.style.paddingLeft = e.data.left + "px";
        body.style.paddingRight = e.data.right + "px";
        body.style.maxWidth = e.data.maxWidth+"px";
        body.style.margin = e.data.margin;
        for (const id in messageObserver.scsIFrameBindPadding) {
            messageObserver.scsIFrameBindPadding[id](e);
        }
    } else if (e.data.type === "scsIFrameFreePadding") {
        app.style.position = styleSnapShot["app"].position;
        app.style.top = styleSnapShot["app"].top;
        app.style.overflow = styleSnapShot["app"].overflow;
        scsContent.style.position = styleSnapShot["scsContent"].position;
        scsContent.style.top = styleSnapShot["scsContent"].top;
        body.style.paddingLeft = styleSnapShot["body"].paddingLeft;
        body.style.paddingRight = styleSnapShot["body"].paddingRight;
        body.style.maxWidth = styleSnapShot["body"].maxWidth;
        body.style.margin = styleSnapShot["body"].margin;
        styleSnapShot = {};
        for (const id in messageObserver.scsIFrameFreePadding) {
            messageObserver.scsIFrameFreePadding[id](e);
        }
    } else if (e.data.type === "scsIFrameAuthRefresh") {
        store.dispatch(renewLogin());
    }
});

export function appRunsInEmbeddedIFrame() {
    return window !== window.parent;
}

export default function register(appElement) {
    if (appRunsInEmbeddedIFrame()) {
        document.body.classList.add("iFrameEmbedded");

        const config = {
            attributes            : true,
            attributeOldValue     : false,
            characterData         : true,
            characterDataOldValue : false,
            childList             : true,
            subtree               : true
        };
        const observer = new MutationObserver(() => {
            activeObserverId = Date.now();
            let offsetHeight = appElement.offsetHeight;
            postResizeMessage(offsetHeight);
            window.setTimeout(() => reCheckMutation(appElement, offsetHeight, activeObserverId), 250);
        });
        observer.observe(appElement, config);
    }
}

function reCheckMutation(appElement, formerOffsetHeight, observerId) {
    if (activeObserverId === observerId) {
        let offsetHeight = appElement.offsetHeight;
        if (offsetHeight !== formerOffsetHeight) {
            postResizeMessage(offsetHeight);
            window.setTimeout(() => reCheckMutation(appElement, offsetHeight), 250);
        }
    }
}

let latestPostedHeight = 0;

function postResizeMessage(height) {
    if (height !== latestPostedHeight) {
        latestPostedHeight = height;
        window.parent.postMessage({ type: "scsIFrameResize", height, name: window.name }, "*");
    }
}

let expandData = null;
let expandPromise = null;

messageObserver.scsIFrameFreePadding['scsIFrameExpandReset'] = () => {
    expandData = null;
};

export async function scsIFrameExpand() {
    if (expandData) {
        return expandData;
    } else {
        if (!expandPromise) {

            expandPromise = new Promise((resolve) => {
                const callId = "id-" + Math.random();
                messageObserver.scsIFrameBindPadding[callId] = (e) => {
                    delete messageObserver.scsIFrameBindPadding[callId];
                    expandData = e.data;
                    expandPromise = null;
                    resolve(expandData);
                };
            });

            window.parent.postMessage({ type: "scsIFrameExpand", name: window.name }, "*");
        }
        return expandPromise;
    }
}

export function scsIFrameCollapse() {
    if (expandPromise || expandData) {
        window.parent.postMessage({ type: "scsIFrameCollapse", name: window.name }, "*");
    }
}
