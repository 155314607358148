import React, {useState} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {notification} from "antd";
import Upload from "antd/lib/upload";
import "antd/lib/upload/style/css";
import {InboxOutlined} from "@ant-design/icons";
import {HttpRequestHeader} from "antd/lib/upload/interface";
import ScsIFrameModal from "../ScsIFrameModal/ScsIFrameModal";
import ProcessingInfo from "./ProcessingInfo";
import "./DropZone.css";
import tld from "../../tld";

const Dragger = Upload.Dragger;

type ConnectedDropZoneProps = {
    httpHeaders?: HttpRequestHeader
}

const emptyProgress: CollectionProgress = {done: 0, total: 0};

const progressRegistry = {};

const DropZone: React.VFC<ConnectedDropZoneProps> = ({httpHeaders}) => {
    const {t} = useTranslation();
    const [componentId] = useState<string>(Math.random().toString(16).substr(2, 16));
    const [progress, setProgress] = useState<CollectionProgress>({...emptyProgress});

    if (!progressRegistry[componentId]) {
        progressRegistry[componentId] = {...emptyProgress};
    }

    const openFileProgress = () => {
        progressRegistry[componentId].total = progressRegistry[componentId].total + 1;
        setProgress({...progressRegistry[componentId]});
    };

    const closeFileProgress = () => {
        progressRegistry[componentId].done += 1;
        if (progressRegistry[componentId].done === progressRegistry[componentId].total) {
            progressRegistry[componentId] = {...emptyProgress};
        }
        setProgress({...progressRegistry[componentId]});
    };

    if (httpHeaders) {
        const draggerProps = {
            name: "file",
            multiple: true,
            showUploadList: true,
            action: tld(process.env.REACT_APP_URL_API_UPLOAD) + "/v1/files",
            withCredentials: true,
            headers: httpHeaders,
            onChange(info) {
                const isFileProgressInfo = !!info.event;
                // const fileId = info.file.originFileObj.uid + info.file.originFileObj.name;
                switch (info.file.status) {
                    case "uploading": {
                        // console.log(info.file, info.fileList);
                        if (!isFileProgressInfo) {
                            openFileProgress();
                        }
                        break;
                    }
                    case "done": {
                        closeFileProgress();
                        // setProgress(done === progress.total ? emptyProgress : {...progress, done});
                        // never load all the files after each upload here, this will crash the whole server on bulk upload
                        // POST files is fire and forget, GET files not
                        // 200 uploads => 200 parallel Requests at once
                        // onUpload();
                        break;
                    }
                    case "error": {
                        closeFileProgress();
                        const isAuthError = info.file.error.status === 401;
                        if (isAuthError) {
                            // @todo use redux & dispatch a proper action
                            if (process.env.REACT_APP_URL_APP_SIGNIN) {
                                window.location.href = tld(process.env.REACT_APP_URL_APP_SIGNIN)
                            } else {
                                notification.error({message: t("Nicht alle Uploads waren erfolgreich"), key: "upload"});
                                notification.error({message: t("Sie wurden abgemeldet"), key: "auth"});
                            }
                        }
                        // Message.error(`${info.file.name} file upload failed.`);
                        break;
                    }
                    case "removed":
                    default: {
                        console.log("@todo: handle upload status " + info.file.status);
                    }
                }

                const uploading = info.fileList.filter((file) => (file.status === "uploading"));
                const success = info.fileList.every((file) => (file.status === "done"));
                if (uploading.length === 0) {
                    if (success) {
                        notification.success({message: t("Upload erfolgreich"), key: "upload"});
                    } else {
                        notification.error({message: t("Nicht alle Uploads waren erfolgreich"), key: "upload"});
                    }
                }
            },
        };

        return <>
            <Dragger {...draggerProps} >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">{t("dropZone.click or drag files")}</p>
                <p className="ant-upload-hint">{t("dropZone.upload files here")}</p>
            </Dragger>
            <ScsIFrameModal
                visible={progress.total > 0}
                closable={false}
                footer={null}
            ><ProcessingInfo key="processingInfo" progress={progress} /></ScsIFrameModal>
        </>;
    } else {
        return null;
    }
}

function mapStateToProps(state: RootState): ConnectedDropZoneProps {
    const httpHeaders = state.auth.login && state.auth.login.active
        ? {Authorization: state.auth.login.httpAuthorization}
        : undefined;
    return {httpHeaders};
}

export default connect<{}, {}, ConnectedDropZoneProps, RootState>(mapStateToProps)(DropZone);
