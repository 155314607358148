import {ItemStatus} from "../../../enums";

export const isCollectionComplete = (collection: FileCollectionItem): boolean => {
    const children = collection.children.filter(item => ItemStatus.INACTIVE !== item.status);
    if (children.length === 0) {
        return false;
    }
    if (children.length === 1) {
        const {collectionId, properties: {collectionSequence = 0}} = children[0];

        if (!collectionId) {
            return false;
        }
        return collectionSequence <= 1;
    }

    let sortedCollection = children.sort((a, b) => {
        const collectionSequenceA = a.properties.collectionSequence || 0;
        const collectionSequenceB = b.properties.collectionSequence || 1;

        return collectionSequenceA - collectionSequenceB;
    });

    const hasFirstCollectionSequence = undefined !== sortedCollection[0].properties.collectionSequence
        && sortedCollection[0].properties.collectionSequence <= 1;
    if (!hasFirstCollectionSequence) {
        return false;
    }
    return !sortedCollection.some(({collectionId, properties: {collectionSequence = 1}}, index) => {
        if (!collectionId) {
            return true;
        }
        if (!collectionSequence || index === 0) {
            return;
        }
        const previousCollectionSequence = children[index - 1].properties.collectionSequence || 0;

        return collectionSequence - previousCollectionSequence !== 1;
    })
}
