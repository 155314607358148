import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CloseOutlined} from "@ant-design/icons";

import Message from "./Message";
import Tutorial from "./Tutorial/Tutorial";
import Instruction from "./Instruction/Instruction";

import styles from "./MessageBlock.module.css";

const MessageBlock = ({ daysSinceUpload = null, hasParticipantData = false, hasAccountPlan = false }) => {
    const [showMessage, setShowMessage] = useState(true);
    const { t } = useTranslation();
    const daysToProcess = 2;
    const daysUntilOutdated = 30

    return (
        <>
            { showMessage && (!daysSinceUpload || daysSinceUpload <= daysToProcess || daysSinceUpload >= daysUntilOutdated || !hasParticipantData || !hasAccountPlan)
                ? <div className={styles.content}>
                    <div onClick={() => setShowMessage(!showMessage)}><CloseOutlined className={styles.hideMessage} /></div>
                    { daysSinceUpload !== null
                        ? daysSinceUpload <= daysToProcess
                            ? <Instruction mainTitle={t('messages.your files were uploaded')}
                                           infoTitle={t('messages.note')}
                                           infoText={[t('messages.info text 1'), t('messages.info text 2')]}
                                           link={`${process.env.PUBLIC_URL}/cashflow`}
                                           linkText={t('messages.cashflow statement')}/>
                            : daysSinceUpload >= daysUntilOutdated
                                ? <Message type="exclamation" className={styles.danger}>{t('messages.are your files up to date')}</Message>
                                : null
                        : <Instruction mainTitle={t('messages.no files uploaded')}
                                       message={t('messages.upload your accounting data')}
                                       infoTitle={t('messages.note')}
                                       infoText={[t('messages.info text 1'), t('messages.info text 2')]}
                                       link={`${process.env.PUBLIC_URL}/cashflow`}
                                       linkText={t('messages.cashflow statement')}/>
                    }
                    { daysSinceUpload !== null && (!hasParticipantData || !hasAccountPlan)
                        ? <Message type="warning" className={styles.warning}>{t('messages.upload support files')}</Message>
                        : null
                    }
                    { daysSinceUpload !== null
                        ? <Tutorial label={t('messages.more information')}
                                    link={"https://flowpilots.de/liquiditaetsplaner-tutorial"}
                                    linkTitle={t('messages.tutorial video')}
                        />
                        : <Tutorial label={t('messages.more information')}
                                    link={"https://flowpilots.de/liquiditaetsplaner-tutorial#tutorial-daten-upload"}
                                    linkTitle={t('messages.upload manager video')}
                        />
                    }
                </div>
                : null
            }
        </>
    );
};

export default MessageBlock;
