import React from "react";
import "antd/lib/table/style/css";
import Popover from "antd/lib/popover";
import "antd/lib/popover/style/css";
import {useSelector} from "react-redux";
import {isDevUserSelector} from "../../services/Auth/selectors";

export type DevHoverProps = {
    info: React.ReactNode
}

const DevHover: React.FC<DevHoverProps> = ({
    children,
    info
}) => {
    const isDevUser = useSelector(isDevUserSelector);
    return isDevUser
            ? <Popover
                    placement="right"
                    content={<small style={{whiteSpace:"nowrap"}}>{info}</small>}
            >{children}</Popover>
            : <>{children}</>;
};

export default DevHover
