import moment from "moment";

export function getFileInfo(record: FileItem, t: (string) => string, stackCollection: boolean = false) {
    let lines: string[] = [];
    let nameParts: string[] = [];
    if (record.vendorName) {
        nameParts.push(record.vendorName);
    }
    if (record.formatName) {
        nameParts.push(record.formatName);
    }
    if (nameParts.length) {
        lines.push(nameParts.join(' '));
    }

    let dateLine: string | null = null;
    if (record.properties) {
        const {
            dateFrom,
            dateTo,
            year,
            postingDateTo,
            postingDateFrom,
            label,
            standardAccountSystemNumber,
            accountNumberLength,
            clientNumber
        } = record.properties;
        if(!stackCollection) {
            if (dateFrom && dateTo) {
                const momentDateFrom = moment(dateFrom);
                const momentDateTo = moment(dateTo);
                dateLine = momentDateFrom.format("L") + " - " + momentDateTo.format("L");
            } else if (year) {
                dateLine = '' + record.properties.year;
            }

            if (null !== dateLine) {
                lines.push(dateLine);
            }

            //
            if (postingDateFrom && postingDateTo) {
                lines.push("(" + moment(postingDateFrom).format("L") + " - " + moment(postingDateTo).format("L") + ")");
            }
            //
            if (label) {
                lines.push(`"${label}"`);
            }
        }
        //
        const accountSystemInfo: string[] = [];
        if (standardAccountSystemNumber) {
            accountSystemInfo.push("SKR 0" + standardAccountSystemNumber);
        }
        if (accountNumberLength) {
            accountSystemInfo.push(t("Kontolänge") + " " + accountNumberLength);
        }
        if (accountSystemInfo.length) {
            lines.push(accountSystemInfo.join(" "));
        }
        //
        if (clientNumber) {
            lines.push(t("Unternehmen") + " " + clientNumber);
        }
    }

    return lines
}

export const findFilesTimeRange = (children, propertyFrom, propertyTo) => {
    const fromRange = moment(children.map(child => (child.properties[propertyFrom])).sort((a, b) => moment(b).isBefore(moment(a)) ? 1 : -1)[0])
    const toRange = moment(children.map(child => (child.properties[propertyTo])).sort((a, b) => moment(b).isAfter(moment(a)) ? 1 : -1)[0])

    return fromRange.format("L") + " - " + toRange.format("L");

}
