import axios from 'axios';
import tld from "../../tld";

export function initialize() {
    return {
        type: 'GET_FILES',
        payload: axios.get(tld(process.env.REACT_APP_URL_API_UPLOAD) + '/v1/files', {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }
}
