import React from "react";
import {useTranslation} from "react-i18next";

import Space from "antd/lib/space";
import "antd/lib/space/style/css";
import Spin from "antd/lib/spin";
import "antd/lib/spin/style/css";

interface ProcessingInfoProps {
    progress: CollectionProgress,
}

const ProcessingInfo: React.VFC<ProcessingInfoProps> = ({progress}) => {
    const {t} = useTranslation();
    const percent = Math.round(progress.done / progress.total * 100);
    // the antd progress bar didn't update somehow…
    const progressBar = <div
        style={{
            height:10,
            width:"80%",
            backgroundColor:"#eee",
            borderRadius:4,
        }}
    ><div style={{
        height:10,
        width:percent+"%",
        backgroundColor:"#789",
        borderRadius:4,
    }}/></div>;
    return <Space
            size="large"
            style={{paddingTop:30}}
        >
        <Spin size="large" style={{padding: 30}}/>
        <Space size="large" direction={"vertical"}>
            <h2>{t("Ihre Dateien werden verarbeitet.")}</h2>
            <p>{t("Haben Sie einen Moment Geduld, bis die Verarbeitung Ihrer Dateien abgeschlossen ist.")}</p>
            {progressBar}
            <p>{progress.done} / {progress.total}</p>
            <p>{t("Diese Meldung wird automatisch geschlossen, sobald die Verarbeitung abgeschlossen ist. Halten sie bis dahin den Browser geöffnet.")}</p>
        </Space>
    </Space>;
}

export default ProcessingInfo;
