import React from "react";
import styles from "../MessageBlock.module.css";

const Tutorial = ({label, link, linkTitle}) => {

    return (
        <p className={`${styles.centerText}`}>
            {label}
            <a href={link} target="_blank" rel="noopener noreferrer">
                {linkTitle}
            </a>
        </p>
    );
};

export default Tutorial;