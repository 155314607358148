export const STATUS = {
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    DANGER: "danger"
};

export const defaultFileUploadDates = {
    bookings: {
        first: null,
        last: null
    },
    participantData: {
        first: null,
        last: null
    },
    accountPlan: {
        first: null,
        last: null
    },
}

const getDaysMinusToday = (days) => {
    return new Date().setDate(new Date().getDate()-days)
}

export const mockFileUploadDates = {
    // bookings: {
    //     first: null,
    //     last: null,
    // },
    // bookings: {
    //     first: new Date(),
    //     last: new Date(),
    // },
    // bookings: {
    //     first: getDaysMinusToday(2),
    //     last: getDaysMinusToday(2),
    // },
    bookings: {
        first: getDaysMinusToday(3),
        last: getDaysMinusToday(3),
    },
    // bookings: {
    //     first: getDaysMinusToday(100),
    //     last: getDaysMinusToday(26),
    // },
    // bookings: {
    //     first: getDaysMinusToday(100),
    //     last: getDaysMinusToday(30),
    // },
    // participantData: {
    //     first: null,
    //     last: null
    // },
    participantData: {
        first: new Date(),
        last: new Date(),
    },
    accountPlan: {
        first: null,
        last: null
    },
    // accountPlan: {
    //     first: new Date(),
    //     last: new Date(),
    // },
}

const updateFirstAndLast = (date, dates) => {
    if (dates.first === null || dates.first > date) { dates.first = date }
    if (dates.last === null || dates.last < date) { dates.last = date }
    return dates
}

export const updateFileUploadDates = (file, fileUploadDates) => {
    const myFileUploadDates = {...fileUploadDates}

    switch (file.formatName) {
        case "Buchungsstapel":
            myFileUploadDates.bookings = updateFirstAndLast(new Date(file.uploadTime), myFileUploadDates.bookings);
            return myFileUploadDates
        case "Kontenbeschriftungen":
        case "Sachkonten":
            myFileUploadDates.accountPlan = updateFirstAndLast(new Date(file.uploadTime), myFileUploadDates.accountPlan);
            return myFileUploadDates
        case "Debitoren/Kreditoren":
            myFileUploadDates.participantData = updateFirstAndLast(new Date(file.uploadTime), myFileUploadDates.participantData);
            return myFileUploadDates
        default:
            return myFileUploadDates
    }
}

export const setPercentStatus = (percent, bookingsDate) => {
    if (percent === 100)                    return STATUS.SUCCESS;
    else if (percent > 0 && bookingsDate)   return STATUS.WARNING
    else                                    return STATUS.DANGER
}

export const setDaysUploadedStatus = (days) => {
    if (days < 25)                          return STATUS.SUCCESS;
    else if (days < 30)                     return STATUS.WARNING;
    else                                    return STATUS.DANGER;
};

export const daysSinceUpload = (date) => {
    if (date) {
        return Math.round((new Date() - new Date(date)) / (1000 * 60 * 60 * 24));
    } else {
        return null
    }
}

export const updatePercentComplete = (fileDates) => {
    let myUploadedFileTypes = 0;
    let myFileTypes = 0;
    Object.keys(fileDates).forEach(key => {
        myFileTypes++
        if (fileDates[key].first) {
            myUploadedFileTypes++
        }
    })
    return Math.round(myUploadedFileTypes/myFileTypes * 100);
};
