import React from 'react';
import ReactDOM from 'react-dom';
import moment from "moment";
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import "moment/locale/de";
import "antd/es/notification/style/index.css";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import registerScsIFrameNotifier from './registerScsIFrameNotifier';
import { Provider } from 'react-redux';
import store from './store';

// import for i18n translation
import './i18n';

// something is weired on mobile regarding cache
// when app-upload is displayed in an iframe (authorized context)
// logins can't be requested
// import rejectUnauthorized from './services/Auth/rejectUnauthorized';
// rejectUnauthorized();
// #FEATURE-656 - required for localized date initialization

moment.locale("de_DE");
//

const ErrorBoundary = (props) => {
    if (!process.env.REACT_APP_BUGSNAG_KEY) {
        return <>{props.children}</>;
    }
    Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_KEY,
        enabledReleaseStages: ['production'],
        releaseStage: process.env.NODE_ENV,
        plugins: [new BugsnagPluginReact()],
        logger: null // make Bugsnag silent in console
    });
    const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || undefined;
    return ErrorBoundary ? <ErrorBoundary>{props.children}</ErrorBoundary> : <>{props.children}</>;
}

const appElement = document.getElementById('app');
ReactDOM.render(
    <Provider store={store}>
        <ErrorBoundary>
            <App/>
        </ErrorBoundary>
    </Provider>,
    appElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

registerScsIFrameNotifier(appElement);
