import React, {Suspense} from 'react';
import {connect} from 'react-redux';

import { initialize } from './services/Auth/actions';
import LoadableSpin from './components/LoadableSpin/LoadableSpin';
import Page from './scenes/Page/Page';

import Layout from 'antd/lib/layout';
import 'antd/lib/layout/style/css';
import tld from "./tld";


const {Content} = Layout;

class App extends React.Component {
    componentDidMount() {
        this.props.dispatch(initialize());
    };

    redirectToSignIn() {
        window.location.href = tld(process.env.REACT_APP_URL_APP_SIGNIN)
    };

    render() {
        if (this.props.login && this.props.login.active) {
            return (
                <Suspense fallback="loading">
                    <Layout id="scsContent" style={{height: '100%'}}>
                        <Content style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Page/>
                        </Content>
                    </Layout>
                </Suspense>
            );
        } else {
            if (this.props.login) {
                this.redirectToSignIn();
            }
            return (
                <Suspense fallback="loading">
                    <Layout>
                        <Content style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <LoadableSpin/>
                        </Content>
                    </Layout>
                </Suspense>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        login: state.auth.login
    };
}

export default connect(mapStateToProps)(App);
