export enum RestV1FileStatus {
    ACTIVE = 'active',
    DEPRECATED = 'deprecated',
}

export enum ItemType {
    FILE = 'file',
    COLLECTION = 'collection',
}

export enum ItemStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}
