import React, {useState} from "react";
import ScsIFrameModal from "../ScsIFrameModal/ScsIFrameModal";

import IconText from "../IconText/IconText";
import styles from "./FileTypes.module.css";


const FileTypes = ({ text="default text", description="", links=[], linkText="", note="", status="danger" }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    return (
        <>
            { description
                ? <ScsIFrameModal
                    title={text}
                    visible={modalVisible}
                    onOk={toggleModal}
                    onCancel={toggleModal}
                >
                    <div className={styles.description}>{description}</div>
                    { links.length > 0
                        ? <div>
                            <div className={styles.linkText}>{linkText}</div>
                            <div className={styles.linkBox}>
                                { links.map((link, index) => {
                                    return (
                                        <a href={`${process.env.PUBLIC_URL}/samples/${link}`} key={index} download>
                                            <div className={styles.linkFile}>
                                                {link}
                                            </div>
                                        </a>
                                    )
                                })}
                            </div>
                            { note ? <div className={styles.note}>{note}</div> : null }
                        </div>
                        : null
                    }
                </ScsIFrameModal>
                : null
            }
            <div className={styles.pointer} onClick={toggleModal}>
                <IconText status={status} text={text} />
            </div>
        </>
    );
};

export default FileTypes;
