import axios from 'axios';
import i18next from "i18next";

export default function reducer(state = {
    login: undefined
}, action) {
    switch (action.type) {
        case 'GET_LOGINS_FULFILLED': {
            let login = {
                active: false,
            };
            if (typeof action.payload.data[0] !== 'undefined') {
                const loginResponse = action.payload.data[0];
                login = {
                    id: loginResponse.id,
                    expires: new Date(loginResponse.expires),
                    active: loginResponse.status === 'active',
                    roles: loginResponse.roles,
                    userName: loginResponse.userName,
                    userLocale: loginResponse.userLocale,
                    companyName: loginResponse.companyName,
                    httpAuthorization: loginResponse.http.authorization,
                };
                axios.defaults.headers.common['Authorization'] = loginResponse.http.authorization;
                window.setTimeout(() => i18next.changeLanguage(login.userLocale), 0);
            }
            return {
                ...state,
                login: login
            };
        }
        case 'DELETE_LOGINS_FULFILLED': {
            axios.defaults.headers.common['Authorization'] = null;
            return {
                ...state,
                login: {
                    active: false,
                }
            };
        }
        default: {
            return state;
        }
    }
}
