import React from "react";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import styles from "./IconText.module.css";

const IconText = ( {status="danger", text=""} ) => {

    const statusIcon = () => {
        switch (status) {
            case "danger":
                return <div className={styles.danger}><ExclamationCircleOutlined /></div>;
            case "warning":
                return <div className={styles.warning}><WarningOutlined /></div>;
            case "success":
                return <div className={styles.success}><CheckCircleOutlined /></div>;
            case "info":
                return <div className={styles.info}><QuestionCircleOutlined /></div>;
            case "update":
                return <div className={styles.warning}><ExclamationCircleOutlined /></div>;
            default:
                return <div className={styles.info}><QuestionCircleOutlined /></div>;
        }};

    return (
        <div className={styles.status}>
            { text ? statusIcon() : null }
            <div className={ styles.text }>{text}</div>
        </div>
    );
};

export default IconText;
