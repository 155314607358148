import React from "react";
import Modal from "antd/lib/modal";
import "antd/lib/modal/style/css";
import { ModalProps } from "antd/lib/modal/Modal";
import {
    appRunsInEmbeddedIFrame,
    scsIFrameExpand,
    scsIFrameCollapse
} from "../../registerScsIFrameNotifier";

// noinspection JSUnusedLocalSymbols
function debug(...msg: any[]) {
    // console.log(...msg);
}

type AntDesignGeneralCallback = () => void;

const runsInEmbeddedIFrame = appRunsInEmbeddedIFrame();

export default class ScsIFrameModal extends React.Component<ModalProps> {

    onOpen = () => {
        debug("ScsIFrameModal::onOpen()");
        scsIFrameExpand();
    };

    onClose = () => {
        debug("ScsIFrameModal::onClose()");
        scsIFrameCollapse();
    };

    afterCloseEnhanced = (outerAfterClose?: AntDesignGeneralCallback): AntDesignGeneralCallback => {
        debug("ScsIFrameModal::afterCloseEnhanced()");
        return () => {
            this.onClose();
            if (outerAfterClose) outerAfterClose();
        }
    };

    render() {
        debug("ScsIFrameModal::render()", this.props.visible, runsInEmbeddedIFrame);
        let modalProps: ModalProps = {
            ...this.props,
            getContainer: "#app",
            afterClose: this.afterCloseEnhanced(this.props.afterClose),
        };
        if (this.props.visible && runsInEmbeddedIFrame) {
            this.onOpen();
        }
        return <Modal {...modalProps} >{this.props.children}</Modal>;
    }
}
