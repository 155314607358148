import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import {connect, useDispatch} from 'react-redux';
import Spin from 'antd/lib/spin';
import 'antd/lib/spin/style/css';

import { initialize } from '../../services/Upload/actions';
import Uploader from "../../components/Uploader/Uploader";
import FileTable from "../../components/FileTable/FileTable";

import styles from './Page.module.css'

const Page = ({files, loading}: UploadState) => {
    const [didUpload, setDidUpload] = useState(false)
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(initialize());
    },[dispatch, didUpload]);

    const onUpload = () => {
        setDidUpload(!didUpload);
    }

    return (
        <div className={styles.page}>
            <Uploader files={files} onUpload={onUpload}/>
            { loading
                ? <div className={styles.spinner}><Spin tip={t('loading files')} size="large"/></div>
                : <FileTable files={files}/>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return state.upload;
}

export default connect(mapStateToProps)(Page);
