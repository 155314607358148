import {isCollectionComplete} from "./isCollectionComplete";
import {ErrorType} from "../enums";

function uniqueArray(value, index, self) {
    return self.indexOf(value) === index;
}

function errorReducer(reducedErrors: string[], listItem: UploadListItem): string[] {
    return listItem.errors?.length ? [...reducedErrors, ...listItem.errors] : reducedErrors;
}

export const validateList = (list: UploadList): UploadList => {
    return list.map((listElement) => {
        if (listElement.children) {
            const errors = listElement.children.reduce(errorReducer, listElement.errors || []);
            if (!isCollectionComplete(listElement)) {
                errors.push(ErrorType.COLLECTION_INCOMPLETE);
            }
            return errors?.length ? {...listElement, errors: errors.filter(uniqueArray)} : listElement;
        } else {
            return listElement;
        }
    })
}

