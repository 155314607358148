import React from "react";
import Spin from 'antd/lib/spin';
import 'antd/lib/spin/style/css';

const LoadableSpin = () => {
        return (
            <Spin/>
        );
};

export default LoadableSpin;
