export default function reducer(state = {
    loading: true,
    error: undefined,
    files: undefined,
}, action) {
    switch (action.type) {
        case 'GET_FILES_PENDING': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'GET_FILES_FULFILLED': {
            return {
                ...state,
                loading: false,
                error: undefined,
                files: action.payload.data
            };
        }
        case 'GET_FILES_REJECTED': {
            return {
                ...state,
                loading: false,
                error: "Can not read file list"
                // error: action.payload.response.data.message,
            };
        }
        default: {
            return state;
        }
    }
}
