import {ItemStatus, ItemType, RestV1FileStatus} from "../../enums";

export const transformRestV1Data = (files: RestV1FileInfo[]): UploadList => {
    const mapByCollectionId = files.reduce((map, restFile) => {
        const fileStatus = RestV1FileStatus.DEPRECATED === restFile.status ? ItemStatus.INACTIVE : ItemStatus.ACTIVE;
        const file: FileItem = {
            ...restFile,
            itemType: ItemType.FILE,
            status: fileStatus,
        };
        if (file.collectionId) {
            const collection:FileCollectionItem = map[file.collectionId] ?? {
                itemType: ItemType.COLLECTION,
                status: fileStatus,
                collectionId: file.collectionId,
                name: file.properties.collectionName || file.collectionId,
                uploadTime: file.uploadTime,
                children: [],
            };

            collection.children.push(file);
            if (ItemStatus.ACTIVE === fileStatus) {
                collection.status = fileStatus;
            }

            map[file.collectionId] = collection;
        } else {
            const identifier = 'uniq-'+file.id;
            map[identifier] = file
        }

        return map;
    }, {});

    return Object.values(mapByCollectionId);
}

