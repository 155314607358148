import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import reducer from './reducer';

const middlewares = [];
middlewares.push(promiseMiddleware);
middlewares.push(thunkMiddleware);
if (process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
}

export default createStore(reducer, applyMiddleware(...middlewares));
