import axios from 'axios';
import tld from "../../tld";

export function initialize() {
    return {
        type: 'GET_LOGINS',
        payload: axios.get(tld(process.env.REACT_APP_URL_API_AUTH) + '/v1/logins', {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }
}

export function renewLogin() {
    return initialize();
}

export function logout() {
    return {
        type: 'DELETE_LOGINS',
        payload: axios.delete(tld(process.env.REACT_APP_URL_API_AUTH) + '/v1/logins', {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
    }
}
