import React from "react";
import styles from "./StatusBlock.module.css";

const StatusBlock = ({ title="title", subtitle="subtitle", status="danger" }) => {

    const statusStyles = () => {
        switch (status) {
            case "danger":
                return [styles.title, styles.danger].join(' ');
            case "warning":
                return [styles.title, styles.warning].join(' ');
            case "success":
                return [styles.title, styles.success].join(' ');
            default:
                return [styles.title, styles.success].join(' ');
        }};

    return (
        <div className={styles.status}>
            <div className={ statusStyles() }>{title}</div>
            <div className={ styles.subtitle }>{subtitle}</div>
        </div>

    );
};

export default StatusBlock;
