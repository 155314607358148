import React from "react";
import styles from "../MessageBlock.module.css";

const Instruction = ({mainTitle, message, infoTitle, infoText, link, linkText }) => {

    return (
        <div>
            <p className={styles.title}>{mainTitle}</p>
            { message ? <p>{message}</p> : null }
            <p>
                <span className={styles.title}>{infoTitle}</span>{infoText[0]}<a href={link}>{linkText}</a>{infoText[1]}
            </p>
        </div>
    );
};

export default Instruction;