import React from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {Alert, Table, Tooltip} from "antd";
import "antd/lib/table/style/css";
import {CheckCircleOutlined, MinusCircleOutlined} from "@ant-design/icons";

import styles from "./FileTable.module.css";
import PageHeader from "antd/lib/page-header";
import {transformRestV1Data} from "../../services/Upload/functions";
import {ColumnsType} from "antd/es/table";
import {validateList} from "../../services/Upload/functions/validateList";
import DevHover from "../DevHover/DevHover";
import {ErrorType} from "../../services/Upload/enums";
import {findFilesTimeRange, getFileInfo} from "./utils";
import {ItemStatus, ItemType} from "../../enums";
import tld from "../../tld";

export const getErrorByType = type => {
    switch (type) {
        case ErrorType.FORMAT_NOT_SUPPORTED:
            return "Dateiformat unbekannt";
        case ErrorType.DIFFERENT_COMPANY:
            return "Unternehmen ungültig";
        case ErrorType.COLLECTION_INCOMPLETE:
            return "Buchungsstapel fehlt";
        default:
            return "Unerwartetes Problem";
    }
}

const FileTable: React.VFC<{files?: RestV1FileInfo[]}> = ({files = []}) =>  {
    const { t } = useTranslation();
    const groupedFiles = transformRestV1Data(files)
    const validatedFiles = validateList(groupedFiles);

    const downloadFile = (fileId, fileName, fileType) => {
        axios.get(tld(process.env.REACT_APP_URL_API_UPLOAD) + "/v1/files/" + fileId + "/data", {
            withCredentials: true,
            headers: {
                "Accept-Charset": "utf-8"
            },
            responseType: "blob",
        }).then((response) => {
            const link = document.createElement("a");
            document.body.appendChild(link);
            const url = window.URL.createObjectURL(new Blob([response.data], {type: fileType}));
            // link.style = "display: none";
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const columns: ColumnsType<UploadListItem> = [{
        title: t("uploadTable.status"), dataIndex: "status", key: "status", width: 200,
        render: (text, record) => {
            const {status, errors = []} = record;
            switch (status) {
                case ItemStatus.INACTIVE:
                    return <div className={[styles.status].join(" ")}>
                        <Tooltip title={t("Diese Daten sind nicht aktiv.")}><MinusCircleOutlined/></Tooltip>
                    </div>;
                default:
                    return errors.length ?
                        <div className={styles.errorContainer}>
                            {errors.map(e => <Alert key={e} message={t(getErrorByType(e))} type='info' className={styles.error}/>)}
                        </div>
                        :
                        <div className={[styles.status, styles.success].join(" ")}>
                            <CheckCircleOutlined/>
                        </div>;
            }
        },
    },
    {
        title: t("uploadTable.name"), dataIndex: "name", key: "name",
        render: (text, record) => {
            return ItemType.FILE === record.itemType
                ? <DevHover info={record.id}><a onClick={() => downloadFile(record.id, record.name, record.type)}>{text}</a></DevHover>
                : `${text} (${record.children?.filter(item => item.status !== ItemStatus.INACTIVE).length})`;
        },
        sorter: (a, b) => a.name.localeCompare(b.name),
    }, {
        title: t("uploadTable.upload date"),  dataIndex: "uploadTime",  key: "uploadTime",
        render: (text) => {
            const date = new Date(text);
            const dateText = date.toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
            });
            return <span>{dateText}</span>
        },
        defaultSortOrder: "descend",
        sorter: (a, b) => {
            const d1 = new Date(a.uploadTime);
            const d2 = new Date(b.uploadTime);

            return d1.getTime() - d2.getTime();
        },
    }, {
        title: t("uploadTable.size"),
        dataIndex: "length",
        key: "length",
        render: (text, record: UploadListItem) => {
            if (ItemType.FILE !== record.itemType) {
                return null;
            }

            const fileSize = ((num) => {
                let i;
                for (i = 0; num >= 1024 && i < 4; i++) num /= 1024;
                return [Math.round(num), " ", ["Byte","KB","MB","GB","TB"][i]];
            })(text);
            return <span>{fileSize}</span>
        },
        defaultSortOrder: "descend",
        sorter: (a, b) => {
            const aValue = ItemType.FILE === a.itemType ? a.length : 0;
            const bValue = ItemType.FILE === b.itemType ? b.length : 0

            return aValue - bValue;
        },
    }, {
        title: t("uploadTable.type"),
        dataIndex: "type",
        key: "type",
        render: (text, record: UploadListItem) => {
            switch (record.itemType) {
                case ItemType.COLLECTION:
                    let allStrings = record.children?.map(c => (getFileInfo(c, t, true)))
                    const data = Array.from(new Set(allStrings?.flat()))

                    const range = findFilesTimeRange(record.children, 'dateFrom', 'dateTo')
                    const postingRange = findFilesTimeRange(record.children, 'postingDateFrom', 'postingDateTo')
                    data.splice(1, 0, range)
                    data.splice(2, 0, `(${postingRange})`)

                    return data.map((l, i) => (<span key={i}>{l} <br/></span>));
                case ItemType.FILE:
                    return getFileInfo(record, t).map((l, i) => (<span key={i}>{l} <br/></span>));
                default:
                    return null;
            }
        },
        defaultSortOrder: "ascend",
        sorter: (a, b) => {
            return a.name.localeCompare(b.name);
        },
    }];

    return <>
        <PageHeader title={t("uploaded data")} />
        <Table rowKey={item => ItemType.COLLECTION === item.itemType ? item.collectionId : item.id }
               pagination={ false }
               columns={ columns }
               dataSource={ validatedFiles }
               indentSize={100}
               rowClassName={record => ItemStatus.INACTIVE === record.status ? styles.inactive : ""}
        />
    </>;
};

export default FileTable
