import React from "react";
import {ExclamationOutlined, WarningOutlined} from "@ant-design/icons";
import styles from "./MessageBlock.module.css";

const Message: React.FC<MessageOptions> = ({
    type,
    className,
    children
}) => {
    let icon;
    switch (type) {
        case "warning":
            icon = <WarningOutlined />;
            break;
        case "exclamation":
            icon = <ExclamationOutlined />;
            break;
        default:
    }
    return <p className={`${styles.centerText} ${className}`}>
        {icon}
        <span className={styles.defaultText}>{children}</span>
    </p>;
};

export default Message;
