import React, { useState, useEffect } from "react";

import Layout from "antd/lib/layout";
import "antd/lib/layout/style/css";

import DropZone from '../DropZone/DropZone';
import StatusBlock from "../StatusBlock/StatusBlock";
import FileTypes from "../FileTypes/FileTypes";
import MessageBlock from "../MessageBlock/MessageBlock";

import {
    daysSinceUpload, defaultFileUploadDates,
    setDaysUploadedStatus, setPercentStatus, STATUS,
    updateFileUploadDates, updatePercentComplete,
} from "./Uploader.functions";
import styles from "./Uploader.module.css";
import {useTranslation} from "react-i18next";


const Uploader = ({ files=[], onUpload }) => {
    const [fileDates, setFileDates] = useState( defaultFileUploadDates );
    const { t } = useTranslation();

    useEffect( () => {
        if(files) {
            files.forEach(file => { setFileDates(updateFileUploadDates(file, fileDates)) });
        }
    },[files]);

    return <Layout>
        <div className={styles.uploader}>
            <div className={styles.header}>
                <div className={styles.statusBlock}>
                    { fileDates.bookings.last
                        ? <StatusBlock title={ `${daysSinceUpload(fileDates.bookings.last)} ${daysSinceUpload(fileDates.bookings.last) === 1 ? t('day') : t('days')}` }
                                       subtitle={ t('since last upload') }
                                       status={ setDaysUploadedStatus(daysSinceUpload(fileDates.bookings.last)) }/>
                        : null
                    }
                    <StatusBlock title={ `${updatePercentComplete(fileDates)}%` }
                                 subtitle={ t('complete') }
                                 status={ setPercentStatus(updatePercentComplete(fileDates), fileDates.bookings.last) }/>
                </div>
            </div>
            <DropZone onUpload={onUpload}/>
            <div className={styles.fileTypes}>
                <MessageBlock daysSinceUpload={daysSinceUpload(fileDates.bookings.last)}
                              hasParticipantData= {!!fileDates.participantData.first}
                              hasAccountPlan = {!!fileDates.accountPlan.first}
                />
                <div className={styles.fileTypesBlock}>
                    <div className={styles.primaryFileType}>
                        <FileTypes text={ t("bookings.title") }
                                   description={ t("bookings.description") }
                                   links={ t("bookings.links", { returnObjects: true }) }
                                   linkText={ t("bookings.linkText") }
                                   note={ t("bookings.note") }
                                   status={ fileDates.bookings.first ? STATUS.SUCCESS : STATUS.DANGER }
                        />
                    </div>
                    <div className={styles.secondaryFileTypes}>
                        <div className={styles.secondaryFilesBlock}>
                            <FileTypes text={ t("participantData.title") }
                                       description={ t("participantData.description") }
                                       links={ t("participantData.links", { returnObjects: true }) }
                                       linkText={ t("participantData.linkText") }
                                       note={ t("participantData.note") }
                                       status={ fileDates.participantData.first
                                           ? STATUS.SUCCESS
                                           : fileDates.bookings.first
                                               ? STATUS.WARNING
                                               : STATUS.INFO
                                       }
                            />
                            <FileTypes text={ t("accountPlan.title") }
                                       description={ t("accountPlan.description") }
                                       links={ t("accountPlan.links", { returnObjects: true }) }
                                       linkText={ t("accountPlan.linkText") }
                                       note={ t("accountPlan.note") }
                                       status={ fileDates.accountPlan.first
                                           ? STATUS.SUCCESS
                                           : fileDates.bookings.first
                                               ? STATUS.WARNING
                                               : STATUS.INFO
                                       }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default Uploader;
